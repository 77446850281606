<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text"><b>Info Desa</b></div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Form :form="form" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/view/components/websites/VillageForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "website-news",
  data() {
    return {
      form: {
        name: "",
        address: "",
        number_of_citizen: "",
        description: "",
        best_commodity: "",
        best_tourism: "",
        area: "",
        agriculture_area: "",
        best_selling_commodity: ""
      },
      FormEditActv: false,
      FormDetailActv: false,
    };
  },
  components: {
    Card,
    Form,
  },
  methods: {
    async get() {
      this.form = await module.get("village-info/1");
      // If Data Not Found
      this.form["_method"] = "put";
      this.form.number_of_citizen = parseInt(this.form.number_of_citizen).toLocaleString("id-ID")
    },

    resetFormOnNew() {
      // reset form on new (tambah data)
      var self = this;
      Object.keys(this.form).forEach(function (key, index) {
        if (key === "image") {
          self.form[key] = [];
        } else if (key === "_method") {
          self.form[key] = "post";
        } else {
          self.form[key] = "";
        }
      });
    },

    scrollToElement(options) {
      let el = this.$el.getElementsByClassName("pagination")[0];
      el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el);
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew();
      }
    },

    scrollToTop(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("alert")[0];
      let er = this.$el.getElementsByClassName("alert");
      // el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el);
      console.log("er", er);
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew();
      }
    },

    btnAddOnClick() {
      // this.scrollToElement({ behavior: 'smooth' })
      this.FormDetailActv = false;
      this.FormEditActv = false;
      this.$bvModal.show("modal-news");
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Info Desa", route: "" }]);

    this.get();
    // Scroll ToTop
    // this.$root.$on("scrollToTop", () => {
    //   // reset form on new (tambah data)
    //   this.resetFormOnNew()
    // });

    // // Add Website Post
    // this.$root.$on("updateWebsitePost", () => {
    //   // reset form on new (tambah data)
    //   this.scrollToTop({ behavior: 'smooth' })
    //   this.resetFormOnNew()
    // });

    //   // Cancel Add Website Post
    // this.$root.$on("cancelAddWebsitePost", () => {
    //   // reset form on new (tambah data)
    //   // this.scrollToTop({ behavior: "smooth" });
    //   this.resetFormOnNew();
    //   this.$bvModal.hide("modal-news");
    // });

    // //  Edit Website Post
    // this.$root.$on("getWebsitePostById", (data) => {
    //   this.FormDetailActv = false
    //   this.FormEditActv = true
    //  this.scrollToElement({ behavior: 'smooth' })
    //   console.log("data", data)

    //   if(data.status == 0){
    //     data.status = false
    //   }else if(data.status == 1){
    //     data.status = true
    //   }

    //  this.form = data
    //  console.log("form", this.form)
    //  this.form['_method'] = 'put'
    // });

    // // Cancel Edit
    // this.$root.$on("cancelWebsitePostUpdate", () => {
    //   this.FormEditActv = false
    //   this.scrollToTop({ behavior: 'smooth' })
    //   this.resetFormOnNew()
    // });

    // // Detail Web Post
    // this.$root.$on("getWebsitePostByIdDetail", () => {
    //   this.$bvModal.show("modal-news");
    //   this.FormDetailActv = true;
    //   this.FormEditActv = false;
    //   //  this.scrollToElement({ behavior: 'smooth' })
    //   //   console.log("data", data)

    //   //   if(data.status == 0){
    //   //     data.status = false
    //   //   }else if(data.status == 1){
    //   //     data.status = true
    //   //   }
    //   //  this.form = data
    // });
  },
};
</script>
